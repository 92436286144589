import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ILoginRequest, IUser } from '@data/interfaces/api/auth.interface';
import { IHttpOptions } from '@data/interfaces/components/http-options-interface';
import { HttpOptionsTools } from '@shared/tools/http-options.tool';
import { StoreTool } from '@shared/tools/store.tool';
import { catchError, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { DataUsuarioService } from '@core/services/api/data-usuario.service';

interface AuthState {
  user: { nombre: string; correo: string; usuario_id: number } | null;
  access_token: string | null;
  access_token_empresa: string | null;
}

const initialState: AuthState = {
  user: null,
  access_token: null,
  access_token_empresa: null,
};

@Injectable({
  providedIn: 'root',
})
export class AuthService extends StoreTool<AuthState> {
  private _http = inject(HttpClient);
  private _httpOptionTool = inject(HttpOptionsTools);
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);
  private _dataUsuarioService = inject(DataUsuarioService);

  constructor() {
    super(initialState);
  }

  set accessToken(accces_token: string) {
    localStorage.setItem('token', accces_token);
  }
  get accessToken(): string {
    return localStorage.getItem('token') ?? '';
  }

  set accessTokenEmpresa(accces_token: string) {
    localStorage.setItem('token_empresa', accces_token);
  }

  get accessTokenEmpresa(): string {
    return localStorage.getItem('token_empresa') ?? '';
  }

  get usuario_id(): number {
    return Number(localStorage.getItem('usuario_id'));
  }

  set usuario_id(id: number) {
    localStorage.setItem('usuario_id', id.toString());
  }

  public login(login: ILoginRequest): Observable<IUser> {
    const httpOption: IHttpOptions = {
      path: 'login',
    };

    const { url } = this._httpOptionTool.runSendData(httpOption);
    return this._http.post<any>(url, login).pipe(
      tap(({ nombre, correo, access_token, usuario_id }) =>
        this.setState(() => ({
          user: { nombre, correo, usuario_id },
          access_token,
        })),
      ),
      tap(({ access_token, usuario_id }) => {
        this.accessToken = access_token;
        this.usuario_id = usuario_id;
      }),
      tap(({}) => {
        const redirectURL = 'login-in-redirect';
        this._router.navigateByUrl(redirectURL);
      }),
      catchError((error) =>
        throwError(() => {
          return error?.status === 401 ? 'Unauthorized' : `${error.statusText}`;
        }),
      ),
    );
  }

  public empresaLogin(codigo: number): Observable<IUser> {
    const httpOption: IHttpOptions = {
      path: 'empresa-login',
      body: { empresa_codigo: codigo },
    };

    const { url, body } = this._httpOptionTool.runSendData(httpOption);
    return this._http.post<any>(url, body).pipe(
      tap(({ nombre, correo, access_token, usuario_id }) =>
        this.setState((state) => ({
          ...state,
          user: { nombre, correo, usuario_id },
          access_token_empresa: access_token,
        })),
      ),
      tap(({ access_token }) => {
        this.accessTokenEmpresa = access_token;
      }),
      tap(({}) => {
        const redirectURL =
          this._activatedRoute.snapshot.queryParamMap.get('redirectURL') ||
          'admin';
        this._router.navigateByUrl(redirectURL);
        localStorage.removeItem('token');
      }),
      catchError((error) => throwError(() => error)),
    );
  }

  public createEmpresaLogin(codigo: number): Observable<IUser> {
    const httpOption: IHttpOptions = {
      path: 'empresa-login',
      body: { empresa_codigo: codigo },
    };

    const { url, body } = this._httpOptionTool.runSendData(httpOption);
    return this._http.post<any>(url, body).pipe(
      tap(({ nombre, correo, access_token, usuario_id }) =>
        this.setState((state) => ({
          ...state,
          user: { nombre, correo, usuario_id },
          access_token_empresa: access_token,
        })),
      ),
      tap(({ access_token }) => {
        this.accessTokenEmpresa = access_token;
      }),
      tap(({}) => {
        localStorage.removeItem('token');
      }),
      catchError((error) => throwError(() => error)),
    );
  }

  public logout(): void {
    this.setState(() => ({ user: null, access_token: null }));
    this.removeUserFromLocalStorage();
    this._router.navigateByUrl('auth');
  }

  public check(): Observable<boolean> {
    return this.selectState(({ user, access_token }) => ({
      user,
      access_token,
    })).pipe(
      switchMap(({ user, access_token }) => {
        if (user && access_token) {
          return of(true);
        }

        if (!this.accessToken) {
          return of(false);
        }

        return of(true);
      }),
    );
  }

  public checkEmpresa(): Observable<boolean> {
    return this.selectState(({ user, access_token_empresa }) => ({
      user,
      access_token_empresa,
    })).pipe(
      switchMap(({ user, access_token_empresa }) => {
        if (user && access_token_empresa) {
          return of(true);
        }

        if (!user) {
          return this.refreshToken();
        }

        return of(true);
      }),
    );
  }

  public refreshToken(): Observable<boolean> {
    const httpOption: IHttpOptions = {
      path: 'empresa-refresh',
      body: {
        empresa_codigo: this._dataUsuarioService.empresaCodigo,
      },
    };
    const { url, body } = this._httpOptionTool.runSendData(httpOption);
    return this._http.post<any>(url, body).pipe(
      switchMap(({ nombre, correo, access_token, usuario_id }) => {
        this.accessTokenEmpresa = access_token;
        this.setState(() => ({
          user: { nombre, correo, usuario_id },
          access_token,
        }));
        this.checkEmpresa();
        return of(true);
      }),
      catchError(() => {
        this.removeUserFromLocalStorage();
        return of(false);
      }),
    );
  }

  private removeUserFromLocalStorage(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('token_empresa');
    localStorage.removeItem('usuario_id');
    localStorage.removeItem('empresa_codigo');
  }
}
